/* Do JS notification via CSS (a la Modernizr) */
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){
    checkWidth();
    if ($('form').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active');
            $(this).find(".js-float-wrap").FloatLabel();
            if(! $(this).hasClass('search')){
                $(this).validate();
            }
        });
    }
    sticky();
    scrollers();
    bannerRotate();
    setupSlideMenu();
    responsiveTables();
    $("#map-click").click(function(){
        $(this).hide();
    });
});
$(window).on('load', function(){
    topBlock();
});
$(window).resize(function(){
    responsiveTables();
    checkWidth();
});

function setupSlideMenu(){
    $('.mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

function responsiveTables(){
    $(".page-content table").each(function(){
        if ($(this).width() > $(".page-content").width()) {
            if (!$(this).parent().hasClass("table-responsive-wrap")){
                $(this).wrap('<div class="table-responsive-wrap"></div>');
            }
        } else {
            $(this).unwrap();
        }
    });
}

function sticky(){
    $(document).scroll(function(){
        if($(window).scrollTop() > 0){
            $('header').addClass('sticky');
            /*for banner blocks
            $('main').addClass('sticky');*/
        } else {
            $('header').removeClass('sticky');
            /*$('main').removeClass('sticky');*/
        }
    });
}

function checkWidth(){
    if (window.matchMedia("(max-width: 1000px)").matches){
        $(".dynamic").removeClass("js-top-block");
        $(".dynamic").css("height", "auto");
    } else {
        if (!$(".dynamic").hasClass("js-top-block")){
            $(".dynamic").addClass("js-top-block");
        }
        topBlock();
    }
}
function topBlock(){
    if($(".js-top-block").length > 0){
        var $block = $(".js-top-block");
        var screen = $(window).height();
        var p_banner = $(".page-graphic").outerHeight();
        var h = screen - p_banner;
        var $content = $block.children(".wrap");
        if ($content[0].scrollHeight <= $content[0].clientHeight){
            $block.css("height", h);
        }
        while($content[0].scrollHeight <= $content[0].clientHeight) {
            var $first = $(".page-content").children().first();
            if ($first.length > 0){
                var $copy = $first.clone();
                $content.append($copy);
                if ($content[0].scrollHeight <= $content[0].clientHeight){
                    $(".page-content").children().first().remove();
                } else {
                    $content.find($copy).remove();
                    break;
                }
            } else {
                break;
            }
        }
    }
}

function checkScrollerSize($wrap){
    $wrap.each(function(index, element){
        var $scroller = $(this).find('.scroller');
        if ($scroller.scrollLeft() == 0){
            $(this).find('#prev').hide();
        } else {
            $(this).find('#prev').show();
        }
        if ($scroller.scrollLeft() + $scroller.innerWidth() >= $scroller[0].scrollWidth) {
            $(this).find('#next').hide();
        } else {
            $(this).find('#next').show();
        }
    });
}
function scrollers(){
    if ($('.scroller .frame').length > 1){
        $('.scroll-nav').show();
        var $wrap = $('.scroll-wrap');

        $wrap.each(function(index, element) {
            var $scroller = $wrap.find('.scroller');
            var $next = $wrap.find('#next');
            var $prev = $wrap.find('#prev');
            var $tiles = $('.scroll-nav h3');

            function slide(offset){
                var $distance = $scroller.innerWidth();
                var $before = $scroller.find('.frame.active').prev();
                var $after = $scroller.find('.frame.active').next();
                if (!$scroller.data('animating')){
                    $scroller.data('animating', true);
                    if(offset < 0){ //back
                        if ($scroller.scrollLeft() > 0){
                            var newPos = $scroller.scrollLeft() - $distance;
                            $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                                checkScrollerSize($wrap);
                                $scroller.data('animating', false);
                            });
                            // set active
                            $scroller.find('.frame.active').removeClass('active');
                            $before.addClass('active');
                            var $id = $before.data('id');
                            $tiles.removeClass('active');
                            $('#'+ $id).addClass('active');
                        } else $prev.hide();
                    } else if (offset == 1) { //fwd
                        if ($scroller.scrollLeft() + $scroller.innerWidth() < $scroller[0].scrollWidth){
                            var newPos = $scroller.scrollLeft() + $distance;
                            $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                                checkScrollerSize($wrap);
                                $scroller.data('animating', false);
                            });
                            // set active
                            $scroller.find('.frame.active').removeClass('active');
                            $after.addClass('active');
                            var $id = $after.data('id');
                            $tiles.removeClass('active');
                            $('#'+ $id).addClass('active');
                        } else $next.hide();
                    } else { //tile click id
                        var $id = offset;
                        var $new = $scroller.find('.frame[data-id='+$id+']');
                        var newPos = $scroller.scrollLeft() + ($new.position().left - (($wrap.outerWidth() - $distance)/2));

                        $tiles.removeClass('active');
                        $('#'+ $id).addClass('active');
                        $scroller.find('.frame.active').removeClass('active');
                        $new.addClass('active');
                        $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                            checkScrollerSize($wrap);
                            $scroller.data('animating', false);
                        });
                    }
                }
            }
            // click functions
            $prev.on('click touch',function(){
                slide(-1);
            });
            $next.on('click touch',function(){
                slide(1);
            });
            $tiles.each(function(index, element){
                var $tile = $(element);
                $tile.on('click touch', function(){
                    var id = $(this).attr('id');
                    slide(id);
                });
            });
        });
        $(window).resize(function(){checkScrollerSize($wrap);});
        checkScrollerSize($wrap);
    }
}

function bannerRotate(){
    var BANNER_DELAY = 5000;
 
    $(".banners-home").each(function(index,el){
        var $banner = $(el);
        var banners = $banner.find(".banner");
        var interval = null;
        if (banners.length > 1) {
            $banner.find('.banner-navigation').show();
            interval = setInterval(moveNext, BANNER_DELAY);
            $banner.find('.banner-nav-item').on('click', navClick);
            $banner.find(".move-next").on('click', moveNext);
            $banner.find(".move-prev").on('click', moveBack);
        } else if (banners.length == 0){
            $banner.remove();
        }
        function swapBanner(image){
            clearInterval(interval);
            var index = image.index();
            $banner.find('.banner').removeClass('active');
            $banner.find('.banner-nav-item').removeClass('active');
            image.addClass('active');
            $banner.find('.banner-nav-item').eq(index).addClass('active');
            interval=setInterval(moveNext, BANNER_DELAY);
        }
        function selectNextBanner(){
            var $current = $banner.find('.banner.active');
            var $next = $current.next();
            if ($next.length == 0){
                $next = $banner.find('.banner:first');
            }
            return $next;
        }
        function selectPreviousBanner(){
            var $current = $banner.find('.banner.active');
            var $prev = $current.prev();
            if ($prev.length == 0){
                $prev = $banner.find('.banner:last');
            }
            return $prev;
        }
        function moveNext(){
            swapBanner(selectNextBanner());
        }
        function moveBack(){
            swapBanner(selectPreviousBanner());
        }
        function navClick(e){
            var $nav = $(this);
            var index = $nav.index();
            var image = $banner.find(".banner").eq(index);
            swapBanner(image);
        }
    });
}
